import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  promotions,
  mobilePromotion,
  menu1,
  menu2,
  menu3,
  menu4,
  menu5,
} from 'images'
// import Slider from 'react-slick'

// const settings = {
//   infinite: true,
//   speed: 1000,
//   arrows: false,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 2000,
//   cssEase: 'linear',
//   pauseOnHover: false,
//   draggable: true,
//   swipe: true,
// }

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center m="0 auto" w="100%">
          <CFImage src={mobilePromotion} w="100%" alt="Promotions" />
          <CFView
            white
            h2
            w="90%"
            mb="25px"
            style={{ lineHeight: '35px' }}
            textCenter
          >
            Clients can simply plan their lunches one day in advance and our
            team has designed an efficient delivery system with proper packaging
            and labels to ensure the food remains fresh and retains its quality
            during transportation and be delivered to each employee with their
            meals properly according to their dietary needs.
          </CFView>
          <CFView
            white
            h2
            w="90%"
            mb="25px"
            style={{ lineHeight: '35px' }}
            textCenter
          >
            The central kitchen is located in a licensed facility and food
            preparation processes meeting all health and safety regulations
            which are crucial for building trust with our customers. We
            seriously consider eco-friendly practices in our operations using
            sustainable packaging and sourcing ingredients responsibly. Twinkle
            Thai encourage feedback from customers and is open to making
            improvements based on their suggestions and will continuously refine
            our menu and services accordingly.
          </CFView>
          <CFView
            white
            h2
            w="90%"
            mb="25px"
            style={{ lineHeight: '35px' }}
            textCenter
          >
            Our kitchen is located at 7080 Kingsway in Burnaby, across from
            Highgate village and clients are welcome to drop by and discussing
            your corporate needs with us. We can deliver orders to our clients
            in Burnaby with orders over $100. For orders over $200, we will
            offer a 10% discount. For orders below $100, we won’t able to
            deliver them for clients but they are welcome to pick them up from
            our store during our business hours.
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        {/* <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={menu1}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={menu2}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={menu3}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={menu4}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pv="10px"
          />
          <CFImage
            src={menu5}
            style={{ objectFit: 'contain' }}
            ph="25px"
            pv="25px"
          />
        </Slider> */}
        <CFView column center maxWidth="1400px" m="0 auto" w="100%">
          <CFImage src={promotions} w="100%" alt="Promotions" />
          <CFView white h3 w="90%" mb="25px" style={{ lineHeight: '35px' }}>
            Clients can simply plan their lunches one day in advance and our
            team has designed an efficient delivery system with proper packaging
            and labels to ensure the food remains fresh and retains its quality
            during transportation and be delivered to each employee with their
            meals properly according to their dietary needs.
          </CFView>
          <CFView white h3 w="90%" mb="25px" style={{ lineHeight: '35px' }}>
            The central kitchen is located in a licensed facility and food
            preparation processes meeting all health and safety regulations
            which are crucial for building trust with our customers. We
            seriously consider eco-friendly practices in our operations using
            sustainable packaging and sourcing ingredients responsibly. Twinkle
            Thai encourage feedback from customers and is open to making
            improvements based on their suggestions and will continuously refine
            our menu and services accordingly.
          </CFView>
          <CFView white h3 w="90%" mb="25px" style={{ lineHeight: '35px' }}>
            Our kitchen is located at 7080 Kingsway in Burnaby, across from
            Highgate village and clients are welcome to drop by and discussing
            your corporate needs with us. We can deliver orders to our clients
            in Burnaby with orders over $100. For orders over $200, we will
            offer a 10% discount. For orders below $100, we won’t able to
            deliver them for clients but they are welcome to pick them up from
            our store during our business hours.
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
