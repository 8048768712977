import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton, orderPickupButtonMobile } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/y1kmLFvcf8ZJK5owtEv3/locations/6zySVCwmA2IQybekbeUy`}
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="95%"
                  maxWidth="320px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/y1kmLFvcf8ZJK5owtEv3/locations/6zySVCwmA2IQybekbeUy`}
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="32vw"
                  maxWidth="300px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
