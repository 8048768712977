export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1697018148/twinkleweb/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1590261442/general/cflogo.png'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639038559/twinkleweb/about_21_1.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639038566/twinkleweb/Group_1407_1.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1639509550/twinkleweb/hero.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1639509812/twinkleweb/heroText.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1637855768/sushitopia/Group_1338.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639038562/twinkleweb/MAP_14_1.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1639510677/twinkleweb/mobileAbout.jpg'
export const mobileAbout2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1623953230/sushiami/mobileAbout2Update.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1639510870/twinkleweb/mobileContact.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639038616/twinkleweb/Mask_Group_41_1.jpg'
export const mobileLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1635192815/ginger/mobileLogo.png'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639510774/twinkleweb/mobileMap.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639038601/twinkleweb/Group_1402.png'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1697017367/twinkleweb/mobilePromo1-2.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639038263/twinkleweb/button_6.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1697016829/twinkleweb/promotions2.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639038561/twinkleweb/foodGallery_20_1.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1672860018/twinkleweb/mobileGallery2.webp'
export const orderPickupButtonMobile =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1630079843/shobu/OrderPickupButton_7.png'
export const menu1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1697017614/twinkleweb/Menu-01.jpg'
export const menu2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1697017615/twinkleweb/Menu-02.jpg'
export const menu3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1697017614/twinkleweb/Menu-03.jpg'
export const menu4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1697017615/twinkleweb/Menu-04.jpg'
export const menu5 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1697017615/twinkleweb/Menu-05.jpg'
export const gallery1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1636566977/kimusushi/gallery1.jpg'
export const gallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1636566977/kimusushi/gallery2.jpg'
export const gallery3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1636566977/kimusushi/gallery3.jpg'
export const gallery4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1636566977/kimusushi/gallery4.jpg'
